
import { defineComponent, onMounted, reactive, ref } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { getSignatureByUrl, completeInstruction } from "@/api/task";
import { addErrorLog } from "@/api/web";
import wx from "weixin-js-sdk-ts";

export default defineComponent({
  setup() {
    const route = useRoute();
    const store = useStore();
    const state = reactive({
      title: "",
      receiveId: '', // 指令接受id
      url: ""
    });

    const isHideMask = ref(false);

    const hideMask = () => {
      isHideMask.value = true;
    };

    const btnShow = ref(false);

    const toComplateShareInstruction = () => {
      const { params } = route;

      const v = {
        instructionId: params.instructionId,
        receiveId: params.receiveId
      };

      if (v.instructionId && v.receiveId) {
        completeInstruction(v as any);
        hideMask();
      }
    };

    const apiSupport = {
      'updateAppMessageShareData': false,
      'updateTimelineShareData': false,
      'onMenuShareTimeline': false,
      'onMenuShareAppMessage': false,
      'onMenuShareQQ': false,
      'onMenuShareQZone': false,
    };

    const initWxSdk = async (config: any) => {
      wx.config({
        //  debug: true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: config.appId, // 必填，公众号的唯一标识
        timestamp: config.timestamp, // 必填，生成签名的时间戳
        nonceStr: config.nonceStr, // 必填，生成签名的随机串
        signature: config.signature, // 必填，签名
        jsApiList: [
          'updateAppMessageShareData',
          'updateTimelineShareData',
          // 以下api在新版本中会弃用，须检查是否可用
          'onMenuShareTimeline',
          'onMenuShareAppMessage',
          'onMenuShareQQ',
          'onMenuShareQZone',
        ],
        openTagList: []
      });

      await new Promise((resolve) => {
        wx.ready(() => {
          resolve(true);
        });
        wx.error(function(err: any) {
          config.userId = store.state.userId;
          addErrorLog({
            msg: `${JSON.stringify(config)} ------ ${JSON.stringify(err)}`
          });
          // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
        });
      });

      await new Promise((resolve) => {
        wx.checkJsApi({
          // 需要检测的 JS 接口列表，所有 JS 接口列表见附录2,
          jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData', 'onMenuShareTimeline', 'onMenuShareAppMessage', 'onMenuShareQQ', 'onMenuShareQZone'],
          success: function(checkRes) {
            // 以键值对的形式返回，可用的 api 值true，不可用为false
            // 如：{"checkResult":{"chooseImage":true},"errMsg":"checkJsApi:ok"}
            Object.assign(apiSupport, checkRes.checkResult);
            btnShow.value = !(apiSupport.onMenuShareTimeline && apiSupport.onMenuShareAppMessage);
            resolve(true);
          }
        });
      });
    }

    const initShareMenu = () => {
      const shareUrl = `${location.origin}/taskShare.html?receiveId=${state.receiveId}&url=${encodeURIComponent(state.url)}`

      if (apiSupport.onMenuShareTimeline) {
        wx.onMenuShareTimeline({
          title: state.title, // 分享标题
          link: shareUrl, // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
          imgUrl: route.query.logo as string, // 分享图标
          success: function () {
            // 用户点击了分享后执行的回调函数
            toComplateShareInstruction();
          },
          cancel() { return 'onMenuShareTimeline:cancel'; }
        });
      } else {
        // 自定义“分享到朋友圈”及“分享到 QQ 空间”按钮的分享内容（1.4.0）
        wx.updateTimelineShareData({
          title: state.title, // 分享标题
          link: shareUrl, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: route.query.logo as string, // 分享图标
          success: function() { return 'updateTimelineShareData:ok'; },
          cancel() { return 'updateTimelineShareData:cancel'; }
        });
      }

      if (apiSupport.onMenuShareAppMessage) {
        wx.onMenuShareAppMessage({
          title: state.title, // 分享标题
          desc: '', // 分享描述
          link: shareUrl, // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
          imgUrl: route.query.logo as string, // 分享图标
          type: 'link', // 分享类型,music、video或link，不填默认为link
          dataUrl: '', // 如果 type 是music或video，则要提供数据链接，默认为空
          success: function () {
            // 用户点击了分享后执行的回调函数
            toComplateShareInstruction();
          },
          cancel() { return 'onMenuShareAppMessage:cancel'; }
        });
      } else {
        // 自定义“分享给朋友”及“分享到QQ”按钮的分享内容（1.4.0）
        wx.updateAppMessageShareData({
          title: state.title, // 分享标题
          desc: '', // 分享描述
          link: shareUrl, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: route.query.logo as string, // 分享图标
          success: function() { return 'updateAppMessageShareData:ok'; },
          cancel() { return 'updateAppMessageShareData:cancel'; }
        });
      }

      if (apiSupport.onMenuShareQQ) {
        wx.onMenuShareQQ({
          title: state.title, // 分享标题
          desc: '', // 分享描述
          link: shareUrl, // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
          imgUrl: route.query.logo as string, // 分享图标
          success: function () {
            // 用户点击了分享后执行的回调函数
            toComplateShareInstruction();
          },
          cancel() { return 'onMenuShareQQ:cancel'; }
        });
      }

      if (apiSupport.onMenuShareQZone) {
        wx.onMenuShareQZone({
          title: state.title, // 分享标题
          desc: '', // 分享描述
          link: shareUrl, // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
          imgUrl: route.query.logo as string, // 分享图标
          success: function () {
            // 用户点击了分享后执行的回调函数
            toComplateShareInstruction();
          },
          cancel() { return 'onMenuShareQZone:cancel'; }
        });
      }
    }

    const init = async () => {
      instructionId.value = (route.params as any).instructionId || "";
      state.receiveId = (route.params as any).receiveId || '';
      state.title = (route.query as any).title || "";
      state.url = (route.query as any).url || "";

      if (!instructionId.value) {
        location.href = state.url;
        return;
      }

      const res = await getSignatureByUrl({
        url: encodeURIComponent(location.href.split("#")[0])
      });

      if ((res as any).resp_code === 0) {
        const config = (res as any).datas;
        await initWxSdk(config);
        initShareMenu();
      }
    };

    const maskImg = ref(require("@/assets/image/guide.svg"));

    const instructionId = ref("");

    onMounted(() => {
      init();
      const u = navigator.userAgent;
      const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
      if (isiOS) {
        if (location.href.indexOf("redurts") == -1) {
          location.href = location.href + "#redurts";
          location.reload();
        }
      } else {
        return "andriod";
      }
    });

    return {
      maskImg,
      state,
      instructionId,
      isHideMask,
      hideMask,
      toComplateShareInstruction,
      btnShow
    };
  }
});
